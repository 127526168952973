#sexo label {
    display:inline;
    width:100px;
    text-align:left;
    font-weight:normal;
    overflow:hidden;

}

.masculino {
    margin-left: 15px;
}

.feminino {
    margin-left: 15px;
}

.form-control{
    font-weight: 0px;
}