aside.logo {

    display: flex;
    justify-content: center;
    align-items: center;
}

aside.logo img {
    padding:  0 15px;
    width: 108%;

}

@media(max-width: 576px){
    aside {
        width: 100%;
    }

    aside.logo img {
        width: 50%;
        margin-top: 10px;
    }

    aside.logo {

        height: 50px;
    }
#log {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
}