.modal1 {
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background: rgba(0,0,0, 0.3);
     z-index: 99;
}

.modal1 .container {
     background-color:rgb(187, 204, 183);
     position: fixed;
     max-width: 430px;
     height: auto;
     top: 10%;
     left: 0;
     right: 0;
     margin: 0 auto;
}
.tabelaModal #table2{
     background-color:rgb(187, 243, 173);
}

#table2 {
     margin-top: -7px;
     max-width: 370px;
}

.btnModal {
     width: 68px;
     height: 32px;
     margin-top: 5px;
     border-radius: 5px;
}

.lblModal {
     margin-top: 15px;
}



@media only screen and (max-width: 576px) {
     .container {
          font-size: 12px;
     }
}