* {
    margin: 0px;
    padding: 0px;
}

.cadAvisoVisualiza {
    flex-direction: column;
    width: 100%;
}


.mensagem {
    display: flex;
    align-items: center;
    background-color: #f5f2f2;
    border-style: dotted;
    border-width: 1px;
    border-radius: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    height: 60px;
    width: 95%;
}

.cont {
    font-weight: bold;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 26px;
}

.aviso {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width:100%;
    margin-top: 10px;
    overflow: auto;
}

.btnNovo {
    margin-top: 15px;
}

.assuntoVisualiza {
    margin-left: 0px;
}

.avisoVisualizador{
    margin-left: 95px;
    margin-top: -26px;
    font-size: 17px;
}

.dataVisualizador{
    margin-top: 7px;
    margin-bottom: 7px;
}

.btnVisualizador {
    width: 300px;
    font-size: 17px;
    margin-left: 5px;
    margin-top: 60px;
}

.fieldEditor {
    margin-left: -17px;
    width: 50%;
}

.tabela {
    border-width: 1px;
    border-style: dotted;
    max-width: 95%;
    border-radius: 10px;
}

@media only screen and (max-width: 768px){
    .fieldEditor {
        margin-left: 20px;
        width: 100%;

    }

    .btnVisualizador {
        margin-left: 10px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 576px) {
    .mensagem {
        max-width: 120%;
    }

    .cont {
        font-size: 15px;
        font-weight: 200;
    }

    .fieldEditor {
        margin-left: -10px;
        width: auto;
    }
   
    .btnVisualizador {
        margin-top: 30px;
        font-size: 12px;
    }

    .avisoVisualizador{
        margin-left: 95px;
        margin-top: -24px;
        font-size: 17px;
    }
    
    .dataVisualizador{
        margin-top: 7px;
        margin-bottom: 7px;
    }
}