* {
    padding: 0px;
    margin: 0px;
}
.agenda {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 250px;
    margin-left: 20px;
    margin-right: 30px;
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    background-color: #d9e6ee;
}

.paramAgenda .capacidade {
    width: 140px;
    font-size: 12px;
    margin-left: 11px;
}

.paramAgenda .taxa {
    width: 140px;
    font-size: 12px;
    margin-left: 5px;
}

.paramAgenda .reserva {
    width: 140px;
    font-size: 12px;
    margin-left: 3px;
    margin-left: 11px;
}

.paramAgenda .canc {
    width: 145px;
    font-size: 12px;
    margin-left: 40px;
    margin-left: 5px;
}

.agenda label {
    display: flex;
    justify-content: center;
    border-bottom: dotted 1px #ddd;
    width: 80%;
    font-size: 30px;
}

.paramAgenda {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    align-content: start;
    margin-bottom: 20px;
}

.buttonAgenda {
    margin-left: 130px;

}

.buttonAgenda button {
    border-radius: 5px;
    width: 130px;
    background-color: #FFF;
    margin-top: 20px;
}

.buttonAgenda button:hover {
    box-shadow: 0 6px 6px #2a77aa;
    transition: all .3s ease-in-out;
}

.buttonAgenda button i {
    padding-right: 10px;
    
}
