.auth-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
}

.auth-modal {
    background-color: #FFF;
    width: 350px;
    padding: 35px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .inputPass {
    height: 40px;
    width: 100%;
    padding: 8px;
    outline: unset;
    background-color: #f1f1f1;
    border: unset;
    border-radius: 4px 0px 0px 4px;
    font-size: 18px;
  }
  

.senha {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-bottom: 15px;
  padding: 3px 8px;
  outline: none;
}


.nova {
    display: flex;
    justify-content: center;
    width: 100%;
}

.auth-title {
    font-size: 1.2rem;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 15px;
}

.auth-modal .email {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 100%;
    margin-bottom: 15px;
    padding: 3px 8px;
    outline: none;
}

.auth-modal button {
    align-items: center;
    color: #FFF;
    padding: 5px 15px;
    width: 100%;
}

.auth-modal a {
    margin-top: 35px;
}

.auth-modal hr {
    border: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, rgba(120,120,120,0),rgba(120,120,120,0.75),rgba(120,120,120,0));
}

.password{
display: flex;
flex-direction: column;
justify-items: flex-end;

}
.buttonVisible {
    cursor: pointer;
    height: 38px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #D3D3D3;
  }
  
  svg{
    opacity: 1;
    animation: fadeIn 0.3s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @media(max-width: 400px) {
    .senha {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      width: 70%;
      margin-bottom: 15px;
      padding: 3px 0px;
      outline: none;
    }
}