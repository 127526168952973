* {
    margin: 0;
    padding: 0;
}

.cadAviso {
    flex-direction: column;
    width: 100%;
}

.cadAviso input,select {
    width: 60%;
    margin-bottom: 20px;
}

.cadAviso .field {
    flex-direction: column;
    width: 100%;
}

.lblDestinatario {
    margin-top: -5px;
}

.destinatario {
    max-width: 60%;
}

.assunto {
    max-width: 60%;
    margin-left: 0px;
}

.field label {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
}

.editorCadastro {
    width: 80%;
}

@media only screen and (max-width: 576px) {
    .cadAviso {
        width: 95%;

    }

    .editorCadastro {
        margin-left: 1px;
        width: 100%;
    }

    .cadAviso input,select {
        width: 100%;
        margin-bottom: 20px;
    }

    .destinatario {
        max-width: 100%;
    }
    
    .assunto {
        max-width: 100%;
        margin-left: 0px;
    }
    
    .btnSalvar {
        margin-top: -10px;
    }
}