* {
  margin: 0;
  padding: 0;
}

.rowReserva{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 37px;
}

.botaoMaior {
  background-color: rgb(215, 215, 241);
}

#btnRes {
  width: 120px;
  height: 110px;
  border-radius: 5%;
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
  color: rgb(223, 13, 13);
}

.btnQuadraTenis {
  background-image: url('/src/imagens/Quadra.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
}



@media only screen and (max-width: 576px) {
  #btnRes {
  width: 80px;
  height: 70px;
  font-size: 10px;

  }

  h2{
    font-size: 18px;
  }

  .btnSairReserva {
    margin-top: 10px;
  }
}
