.modalregras {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, 0.3);
    z-index: 99;
    overflow:scroll; 
}

.titulo label {
font-size: 25px;
}

.titulo i {
    font-size: 25px;
    margin-right: 5px;
}

.infoRegras i{ 
    padding: 5px;
}

.modalregras .container {
    background-color:#fff;
    max-width: 600px;
    height: auto;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    
}