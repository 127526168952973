.grupo {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 15px;
}

@media only screen and (max-width: 576px) {
    .grupo {
        width: 100%;
    }
}