.row1{
  display: flex;
}

#btnDoc1 {
  width: 120px;
  height: 110px;
  border-radius: 5%;
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
}

.btnAtas {
  background-color: rgb(240, 192, 134);
}
.row1 .btnAtas:hover {
    background-color: rgba(240, 192, 134, 0.2);
    transition: 0.7s;
}

.btnComunicados {
  background-color: rgb(156, 166, 212);
}
.row1 .btnComunicados:hover {
  background-color: rgba(156, 166, 212, 0.2);
  transition: 0.7s;
}

.btnConvencao {
  background-color: rgb(209, 241, 171);
}
.row1 .btnConvencao:hover {
  background-color: rgb(209, 241, 171, 0.2);
  transition: 0.7s;
}

.btnEditais {
  background-color: rgb(233, 170, 230);
}
.row1 .btnEditais:hover {
  background-color: rgb(233, 170, 230, 0.2);
  transition: 0.7s;
}

.btnSairDoc {
    width: 60px;
    height: 50px;
    border-radius: 10%;
    text-align: center;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 60px;
    background-color: rgb(247, 71, 71);
  }
  .row1 .btnSairDoc:hover {
    background-color: rgba(247, 61, 61, 0.2);
    transition: 0.7s;
  }

.tabelaDoc {
  max-width: 60%;
}
.tabelaNome {
  width: 200px;
}

#btnCarregar {
  border-radius: 5px;
  margin-left: 5px;
  height: 35px;
  width: 31px;

}

#btnDoc {
  margin-left: 140px;
}


@media only screen and (max-width: 576px) {
  .btnEditais {
    margin-top: 10px;
  }

  .tabelaDoc {
    max-width: 95%;
  }

}