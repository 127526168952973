.buttonVisibleC {
    cursor: pointer;
    height: 38px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;
    border-left: 0px;
    border-color: #D3D3D3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  