header .item{
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

header.header {
    margin-top: 2px;
    background-color: #fff;
    padding: 2px 15px;
    white-space: nowrap;
    box-shadow: var(--shadow);
    width: 100%;
}

header.header h1 {
    font-size: 1.6em;
}

#drop {
    margin-top: 10px;
}


/* Tablets */
@media only screen and (max-width: 768px) {
    header .item{
        margin-top: 8px;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }
    
    header.header h1 {
        margin-top: -10px;
        font-size: 1.2em;
    }
}


/* Celulares */
@media only screen and (max-width:576px) {
    header .item{
        display: flex;
        flex-direction: row;
        width: 20%;
        flex-wrap: wrap;
    }

    header.header {
        background-color: #fff;
        padding: 2px 15px;
        white-space: nowrap;
        box-shadow: var(--shadow);
        width: 20%;
    }

    header.header h1 {
        font-size: 1.0em;
    }
    
}