.rbc-month-row {
    display: flex; 
    overflow: hidden;
  }

  .rbc-btn-group button {
    background-color: #2D9FED;
    color: white;
  }

  .rbc-header {
    background-color: #138D75 ;
    color: white;
  }

  .rbc-date-cell {
    background-color: #CCD1D1;
  }

  .rbc-toolbar-label {
    background-color: black;
    color: white;
    width: auto;
    height: 32px;
  }

  .titulo {
    height: auto;
    text-align: center;
    font-size: 28px;
  }


  .infoEspaco .infoRegras label {
    color: black;
    background-color:#ecd45c;
    font-size: 13px;
    border-radius: 5px;
    padding: 5px;
  }
  
  .infoEspaco .infoRegras label:hover {
      box-shadow: 5px 5px 5px #806c09;
      transition: all .4s ease-in-out;
  }

  .infoEspaco {
    flex-wrap: wrap;
    width: auto;
    height: auto;
    margin-top: 10px;
  }

  .infoEspaco label {
    color: black;
    background-color: #CCD1D1;
    font-size: 13px;
    margin-left: 50px;
    margin-top: 10px;
    border-radius: 5px;
    padding: 7px;
}


.infoEspaco label i{
  padding-right: 5px;
}

.tituloEspaco {
  font-size: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}


.rbc-event{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 300px;
  white-space: pre-wrap;
  white-space: pre-line;
  word-break:break-all;
}

.rbc-event-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  white-space: pre-wrap;
  white-space: pre-line;
  word-break:break-all;

}


/* celular */
@media only screen and (max-width: 576px) {
  .infoEspaco .infoRegras label {
    color: black;
    background-color:#ecd45c;
    font-size: 13px;
    border-radius: 5px;
    padding: 5px;
  }

  .infoEspaco {
    flex-wrap: wrap;
    width: auto;
    height: auto;
    margin-top: 10px;
  }

  .infoEspaco label {
    color: black;
    background-color: #CCD1D1;
    font-size: 13px;
    margin-left: 20px;
    margin-top: 10px;
    border-radius: 5px;
    padding: 7px;
}

  
}