* {
  margin: 0;
  padding: 0;
}

#menu-div1 {
  width: auto;
  height: auto;
}


#mobile1 {
  display: none;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 30px;

}

.menus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}



/*aqui estão os desenhos no centro da div*/
.menu span {
  font-size: 80px;
  color: white;
 
}

.menu .contador {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.contador label {
text-align: center;
  color: #ffffff;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  background-color: red;
  border-radius: 100%;
}



/* aqui estão os ícones pequenos*/
.link span {
  display: flex;
  width: 190px;
  font-size: 16px;
  color: white;
  margin-top: 40px;
  margin-left: 5px;
  align-items: center;

}

/* aqui está o icone pequeno das Regras de Espaço */
.link span img {
  width: 23px;
  height: 23px;
  margin-left: 3px;
}

.menu:hover {
  top: -12px;
  box-shadow: 0 6px 6px #726f6f;
  transition: all .3s ease-in-out;
}


/*----------- Tablets ------------*/
@media only screen and (max-width: 768px) {
  .menu {
    width: 150px;
    height: 150px;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 15px;
  }

  /*aqui estão os desenhos no centro da div*/
  .menu span {
    margin-top: 5px;
    font-size: 60px;
   }

   /* aqui estão os ícones pequenos*/
   .link span {
    width: 115px;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    margin-left: -21px;
  }

  .menus {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-top: 20px;

  }

    /* aqui está o icone pequeno das Regras de Espaço */
  .link span img {
    margin-left: 2px;
    width: 20px;
    height: 20px;
  }

}



/*--------- Celulares ----------*/
@media only screen and (max-width: 576px) {
 .menu {
   width: 130px;
   height: 130px;
   margin-right: 5px;
   margin-left: 5px;
   margin-bottom: 15px;
 }

 .menu span {
  margin-top: 5px;
  font-size: 50px;
 }

 .menu span img {
  width: 50px;
  height: 50px;
 }
/* aqui estão os ícones pequenos*/
  .link span {
    width: 115px;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;
    margin-left: -2px;
  }

  .menus {
    display: flex;
    flex-direction: row;
    align-items: left;
  }


  /* aqui está o icone pequeno das Regras de Espaço */
  .link span img {
    margin-left: 2px;
    width: 20px;
    height: 20px;
  }
  
}