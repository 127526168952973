.remover {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-items: flex-end;
    width: 100%;
}

.infoEspaco {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

