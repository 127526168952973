.buttonSend {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttonSend button {
    margin-right: 10px;
    padding: 10px;
  }
  
  .buttonSend button image {
    margin-right: 10px;
  }
  
  .fieldAgenda {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: start;
    align-items:flex-start;
    margin-right: 120px;
    margin-bottom: 5px;
  }

  .fieldAgenda select {
    width: 250px;
    margin-right: 50px;
  }

  .fieldAgenda input {
    width: 250px;
    margin-right: 50px;
  }

  .fieldAgenda .dia {
    width: 250px;
    margin-right: 50px;
  }

  .modal3 {
      position: fixed;
      top: 0;
      bottom:0;
      right: 0;
      left: 0;
      background: rgba(0,0,0, 0.6);
      z-index: 99;
  
    }
    
    .modal3 .container {
      display: block;
      position: fixed;
      max-width: 600px;
      max-height: 550px;
      top: 15%;
      left:0;
      right: 0;
      margin: 0 auto;
      padding: 4em 2rem;
      background-color: #FFF;
      box-shadow: 0 0 20px rgba(0,0,0, 0.8);
      border-radius: 50px;
      overflow: auto
    }
    
    .closeAgenda{
      background-color: #F65835;
      border:0;
      color: #FFF;
      position: absolute;
      top: 15px;
      left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      border-radius: 5px;
    }
    
    .closeAgenda svg{
      margin-right: 5px;
    }
    
    .modal3 h2{
      margin-bottom: 1.2em;
      font-size: 2em;
    }
    
    .modal span{
      font-weight: bold;
      font-size: 1.2em;
      color: #121212;
    }
    
    .modal3 span i{
      font-weight: 400;
      margin-right: 1em;
      padding: 2px 8px;
      border-radius: 3px;
    }
    
    .row1{
      margin-bottom: 1em;
    }
    
    .modal3 p{
      padding-top: 0.5em;
      white-space: pre-wrap;
    }