.main-form-cadastro {
  max-width: 50%;
  height: auto;
}

select {
  border-radius: 5px;
  height: 30px;
}

label {
  margin-top: 10px;
}
#nomeEspaco, #dias, #taxa {
  border-radius: 5px;
}

#nomeEspaco{
  margin-top: -8px;
  width: 30%;
}

#dias {
  margin-top: -8px;
  width: 120px;
}

#taxa {
  margin-top: -8px;
  width: 120px;
}

#tipoEspaco {
  width: 120px;
  margin-left: 5px;
  margin-top: -8px;
}

#check {
  width: 120px;
  margin-left: 6px;
  margin-top: -8px;
}

#lblinicio, #lbltermino{
  margin-left: 10px;
}

#table2 {
  margin-top: 12px;
  max-width: 25%;
  font-size: 13px;
}

#tabela {
  max-width: 45%;
}

#btnIncluir {
  border-radius: 5px;
  margin-left: 10px;
}

#btn1 {
  margin-left: 120px;
}

table thead #fim {
  width: 20px;
}

.row1 .btnSairCad {
  margin-left: 15px;
  background-color: rgb(247, 95, 95);
  width: 60px;
  border-radius: 5px;
  border: none;

}

.tipo {
  margin-top: 10px;
}

@media only screen and (max-width: 576px) {
  .main-form-cadastro {
    max-width: 90%;
  }

  #table2 {
    max-width: 50%;
    font-size: 13px;
  }
  
  #table2 thead {
    border: none;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #table2 tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  #table2 td {
    border-bottom: 1px solid #ddd;
    display: block;
    text-align: right;
    height: 26px;
  }


  #nomeEspaco{
    width: 60%;
  }
}