.pass-content {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pass-modal {
    height: 100%;
    background-color: #FFF;
    width: 350px;
    padding: 35px;
       display: flex;
    flex-direction: column;
}

.retornalogin {
        display: flex;
    flex-direction: column;
    align-items: center;
}

.inputRedefinir {
    margin-top: 5px;
    margin-bottom: 10px;
    width: 270px;
}

.buttonRedefinir {
    width: 250px;
    margin-bottom: 5px;
} 

.redefinicao{
    display: flex;
    flex-direction: row;
    width: 250px;
    background-color: #145A32;
}

.redefinicao label{
    color: #FFF;
    padding: 10px;
}

@media(max-width: 768px) {
    .pass-content {
        height: 450px;
    }
}

@media(max-width: 576px) {
    .pass-content {
        height: 100%;
    }
}
