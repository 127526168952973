
.logo {
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: center;
}

.closeIOS{
    background-color: #fff;
    border:0;
    top: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    border-radius: 5px;
  }

.logo img {
    width: 150px;
}

.close {
    display: flex;
    flex-direction: column;
    justify-content: flex-end ;
    align-items: center;
    margin-bottom: 50px;
}

.nomeApp {
    font-weight: bold;
    color: green;
}

.modalIOS {
    position: fixed;
    top: 0;
    bottom:0;
    right: 0;
    left: 0;
    background: rgba(0,0,0, 0.6);
    z-index: 99;

  }
  
  .modalIOS .container {
    display: block;
    position: fixed;
    max-width: 600px;
    max-height: 550px;
    top: 10%;
    left:0;
    right: 0;
    margin: 0 auto;
    background-color: #FFF;
    padding: 2em 1rem;
    box-shadow: 0 0 20px rgba(0,0,0, 0.8);
    border-radius: 50px;
    overflow: auto
  }


.btnClose {

    width: 550px;
}