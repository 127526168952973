.diaSemana {
  width: 150px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.hora {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.hora label {
  margin-left: 10px;
}

.right-section {
  margin-left: 5px;
}
#lblInicio {
  margin-left: 70px;
}
#lblInicioEspecifico {
  margin-left: 146px;
}

#lblFim {
  margin-left: 90px;
}
#lblFimEspecifico {
  margin-left: 86px;
}

.horaInicio {
  width: 110px;
  margin-left: 213px;
  margin-top: -40px;
}

.horaFim {
  width: 110px;
  margin-left: 350px;
  margin-top: -38px;
}

.horaInicioEspecifico {
  width: 110px;
  margin-left: 220px;
  margin-top: -40px;
}

.horaFimEspecifico {
  width: 110px;
  margin-left: 350px;
  margin-top: -38px;
}

#btnIncluirHorario {
  margin-top: 15px;
  margin-left: -2px;
}

/* Tablet */
@media screen and (max-width: 768px){

}


/* Celular */
@media only screen and (max-width: 576px) {
  #lblInicio {
    margin-left: 40px;
  }
  #lblFim {
    margin-left: 79px;
  }
  .horaInicio {
    width: 110px;
    margin-left: 183px;
    margin-top: -40px;
  }
  .horaFim {
    width: 110px;
    margin-left: 310px;
    margin-top: -38px;
  }

  #lblInicioEspecifico {
    margin-left: 110px;
  }
  #lblFimEspecifico {
    margin-left: 83px;
  }
  .horaInicioEspecifico {
    width: 110px;
    margin-left: 183px;
    margin-top: -40px;
  }
  
  .horaFimEspecifico {
    width: 110px;
    margin-left: 311px;
    margin-top: -38px;
  }


  #btnIncluirHorario {
    margin-top: 15px;
    margin-left: -2px;
  }
}